import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useIntl } from "react-intl"
import { FilterBar } from "../components/analytics/FilterBar/FilterBar"
import { MobileNavbar } from "../components/general/MobileNavbar/MobileNavbar"
import { Navbar } from "../components/general/Navbar/Navbar"
import { Notifications } from "../components/general/Notifications/Notifications"
import { Protected } from "../components/general/Protected/Protected"
import { Seo } from "../components/general/Seo/Seo"
import { LogBetaInfo } from "../components/logs/LogBetaInfo/LogBetaInfo"
import { LogsCreateModal } from "../components/logs/LogsCreateModal/LogsCreateModal"
import { LogsList } from "../components/logs/LogsList/LogsList"
import { Footer } from "../components/marketing/Footer"
import { Container } from "../components/UI/Container"

export const LogsRoute: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  return (
    <Protected>
      <Seo
        title={intl.formatMessage({
          id: `seo/indexation/title`,
        })}
        description={intl.formatMessage({
          id: `seo/indexation/description`,
        })}
        lang="en"
        langUrls={[
          {
            lang: "en",
            url: "/settings",
            isDefault: true,
          },
        ]}
      />

      <Navbar />

      <Container className="relative">
        <div className="mt-4" id="pagination-anchor-scroll"></div>

        <LogBetaInfo />

        <div className="mt-4" />

        <FilterBar />
        <LogsList />
      </Container>

      <LogsCreateModal />
      <Footer />
      <MobileNavbar />
      <Notifications />
    </Protected>
  )
}
